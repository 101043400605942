import React from "react";
import { useRoutes } from "react-router-dom";
import { ErrorPage } from "./Pages/404";
import About from "./Pages/About";
import { Certificates } from "./Pages/Certificates";
import CodeCamp from "./Pages/CodeCamp";
import Home from "./Pages/Home";
import ItServices from "./Pages/ItServices";
import OurServices from "./Pages/OurServices";
import UkCohorts from "./Pages/UkCohorts";
import abayomi from "./certs/ABAYOMI_ADEYEYE.png";
import adeniji from "./certs/ADENIJI_LESLEY_ABIMBOLA.png";
import adaeze from "./certs/Adaeze_Cynthia_Anyanwu.png";
import adebayo from "./certs/Adebayo_Emmanuel_Oluwapelumi.png";
import adebiyi from "./certs/Adebiyi_Oluwaseun_Oluwademilade.png";
import adekoya from "./certs/Adekoya_Adeola_helen.png";
import ademisoye from "./certs/Ademisoye_Ebenezer_Olamilekan.png";
import arit from "./certs/Arit_Tom.png";
import augustine from "./certs/Augustine_Aguworumba.png";
import blessing from "./certs/BLESSING_NWOKOLO.png";
import chiagozie from "./certs/Chiagozie_Mbanusi_Lawrence.png";
import chuma from "./certs/Chuma_Chibueze_Charles.png";
import david from "./certs/David_Okoh.png";
import dennis from "./certs/Dennis_Chimaucheya_Nwazuo.png";
import disu from "./certs/Disu_Ismaila_Lekan.png";
import emmanuel from "./certs/Emmanuel_Egundeyi.png";
import ifechukwu from "./certs/Ifechukwu_David_nwokenagu.png";
import james from "./certs/James_Emmanuel.png";
import jeremiah from "./certs/Jeremiah_Odigie.png";
import mustapha from "./certs/Mustapha_Mojeedat_Oluwaseyi.png";
import nworie from "./certs/Nworie_Chibuzor_Godwin_.png";
import olofi from "./certs/OLOFI_OGHENETEGA_MAGDALENE.png";
import obasi from "./certs/Obasi_Emeka_Joseph.png";
import obi from "./certs/Obi_Nkechinyere_Rita.png";
import obilonu from "./certs/Obilonu_Chinonye_Francis.png";
import perpetual from "./certs/Obu-Amu_Perpetual_Otioyinye.png";
import ogunuyo from "./certs/Ogunuyo_Ogheneruemu_Brown.png";
import ojo from "./certs/Ojo_Victoria_Abiola.png";
import olaleye from "./certs/Olaleye-Martins_Damife_Zion.png";
import opeyemi from "./certs/Opeyemi_Odedeyi.png";
import oyesina from "./certs/Oyesina_Dorcas.png";
import simi from "./certs/SIMILOLUWA_O._KARUNWI.png";
import shittu from "./certs/Shittu_Taiwo_Abdulrahman.png";
import ugwu from "./certs/UGWU_DAVID_OLUCHI.png";
import ukaegbu from "./certs/Ukaegbu_Precious_Chidimma.png";
import yasir from "./certs/Yasir_kabir.png";
import adekoya2 from "./assets/ADEKOYA.jpg";
import agwaniru from "./assets//AGWANIRU.jpg";
import ajala from "./assets/AJALA.jpg";
import ajayi from "./assets/AJAYI.jpg";
import baasit from "./assets/BAASIT.jpg";
import fajana from "./assets/FAJANA.jpg";
import bolatito from "./assets/BOLATITO.jpg";
import emeka_akam from "./assets/BOLATITO.jpg";
import chukwu from "./assets/CHUKWUJEKWU.jpg";
import feeyijimi from "./assets/FEYIJIMI-JIMOH.jpg";
import fisayo from "./assets/FISAYO.jpg";
import ifekoya from "./assets/IFEKOYA.jpg";
import iwenjiwe from "./assets/IWENJIWE.jpg";
import michael from "./assets/MICHAEL.jpg";
import nwadiogbu from "./assets/NWADIOGBU.jpg";
import obe from "./assets/OBE.jpg";
import oduyeemi from "./assets/ODUYEMI.jpg";
import ogunjobi from "./assets/OGUNJOBI.jpg";
import ogunleye from "./assets/OGUNLEYE.jpg";
import olaewe from "./assets/OLAEWE.jpg";
import olaitan from "./assets/OLAITAN.jpg";
import olajide from "./assets/OLAJIDE.jpg";
import olowu from "./assets/OLOWU.jpg";
import olusola from "./assets/OLUSOLA.jpg";
import omoyeni from "./assets/OMOYENI.jpg";
import oyanienlen from "./assets/OYANIENLEN.jpg";
import simbiat from "./assets/SIMBIAT.jpg";
import sylvesteer from "./assets/SYLVESTER.jpg";
import karunwi from "./assets/KARUNWI.jpg";
import tolawanimi from "./assets/TOLUWANIMI.jpg";
import ugwu2 from "./assets/UGWU.jpg";
import akhaine from "./assets/AKHAINE.jpg";
import okoh from "./assets/OKOH.jpg";

// July 2024 Certs
import Abednego from './assets/certs July 2024/Abednigo Certificate.jpg'
import Abosede from './assets/certs July 2024/Abosede Certificate.jpg'
import Adegbite from './assets/certs July 2024/AdegbiteCertificate.jpg'
import Adekoya from './assets/certs July 2024/Adekoya Certificate.jpg'
import Adeniji from './assets/certs July 2024/Adeniji Certificate.jpg'
import Adesanya from './assets/certs July 2024/Adesanya Certificate.jpg'
import AdeyemiNic from './assets/certs July 2024/Adeyemi Certificate (1).jpg'
import AdeyemiDoyin from './assets/certs July 2024/Adeyemi Certificate.jpg'
import Musodiq from './assets/certs July 2024/Musodiq Certificate.png'
import Aham from './assets/certs July 2024/Aham Certificate.jpg'
import Aina from './assets/certs July 2024/Aina Certificate.jpg'
import Akinlade from './assets/certs July 2024/Akinlade Certificate.jpg'
import Babatunde from './assets/certs July 2024/Babatunde Certificate.jpg'
import Campbell from './assets/certs July 2024/Campbell Certificate.jpg'
import Anucha from './assets/certs July 2024/Chimdi Certificate.jpg'
import Chinenye from './assets/certs July 2024/Chinenye Certificate.jpg'
import ChineyeAda from './assets/certs July 2024/Chineye Certificate.jpg'
import Chioma from './assets/certs July 2024/Chioma Certificate.jpg'
import Chikwuikem from './assets/certs July 2024/Chukwuikem Certificate.jpg'
import DavidOlu from './assets/certs July 2024/David Certificate.jpg'
import Dedogbe from './assets/certs July 2024/Dedodgbe Certificate.jpg'
import Diana from './assets/certs July 2024/Diana Certificate.jpg'
import Enyagam from './assets/certs July 2024/Enyagam Certificate.jpg'
import Farinde from './assets/certs July 2024/Faarinde Certificate.jpg'
import Godsent from './assets/certs July 2024/Godsent Certificate.jpg'
import Obogu from './assets/certs July 2024/James Certificate.jpg'
import Jolayemi from './assets/certs July 2024/Jolayemi Certificate.jpg'
import Joseph from './assets/certs July 2024/Joseph Certificate.jpg'
import Kalu from './assets/certs July 2024/Kalu Certificate.jpg'
import Kehinde from './assets/certs July 2024/Kehinde Certificate.jpg'
import Khalil from './assets/certs July 2024/Khalil Certificate.jpg'
import Ejiowo from './assets/certs July 2024/Kudirat Certificate.jpg'
import Mariam from './assets/certs July 2024/Mariam Certificate.jpg'
import Maureen from './assets/certs July 2024/Maureen Certificate.jpg'
import MichaelDavid from './assets/certs July 2024/Michael Certificate.jpg'
import Moyosore from './assets/certs July 2024/Moysosore Certificate.png'
import Ogunfuwa from './assets/certs July 2024/Ogunfwa Certificate.jpg'
import Ogunjobi from './assets/certs July 2024/Ogunjobi Certificate.jpg'
import Ohikhena from './assets/certs July 2024/Ohikena Certificate.jpg'
import Okim from './assets/certs July 2024/Okim Certificate.jpg'
import Olabamiji from './assets/certs July 2024/Olabamiji Certificate.png'
import Oladimeji from './assets/certs July 2024/Oladimeji Certificate.jpg'
import Olanrewaju from './assets/certs July 2024/Olanrewaju Certificate.jpg'
import Olawuyi from './assets/certs July 2024/Olawuyi Certificate.jpg'
import Olayode from './assets/certs July 2024/Olayode Certificate.jpg'
import Oluwaseyi from './assets/certs July 2024/Oluwaseyi Certificate.jpg'
import Onyeike from './assets/certs July 2024/Onyeike Certificate.jpg'
import Osobajo from './assets/certs July 2024//Osobajo Certificate.jpg'
import Oyedele from './assets/certs July 2024/Oyedele Certificate.jpg'
import Oyekan from './assets/certs July 2024/Oyekan Certificate.jpg'
import Princely from './assets/certs July 2024/Princely Certificate.jpg'
import Rex from './assets/certs July 2024/Rex Certificate.png'
import Afolabi from './assets/certs July 2024/Ruth Certificate.jpg'
import Silas from './assets/certs July 2024/Silas Certificate.jpg'
import Simon from './assets/certs July 2024/SimonCertificate.jpg'
import Temitope from './assets/certs July 2024/Temitope Certificate.png'
import TijaniOla from './assets/certs July 2024/Tijani Certificate.png'
import Tunde from './assets/certs July 2024/Tunde Certificate.jpg'
import Uje from './assets/certs July 2024/Uje Certificate.jpg' 
import Adeniyi from './assets/certs July 2024/Adeniyi Certificate.jpg'
import Musawir from './assets/certs July 2024/Musawir Certificate.jpg'
import Oluwafe from './assets/certs July 2024/Oluwafe Certificate.jpg'
import Oreka from './assets/certs July 2024/oreka  Certificate.jpg'
import AbdullahiNasiru from './assets/cert Mar. 2025/Abdullahi Nasiru Usman.jpg'
import AhmadUsman from './assets/cert Mar. 2025/Ahmad S. Usman.jpg'
import Ebipadou from './assets/cert Mar. 2025/Ebipadou Asamaowei Kevin.jpg'
import Kalejaiye from './assets/cert Mar. 2025/Gbenga Kalejaiye.jpg'
import Imonite from './assets/cert Mar. 2025/Imonite.jpg'
import Miriam from './assets/cert Mar. 2025/Miriam.jpg'
import Obaje from './assets/cert Mar. 2025/Obaje.jpg'
import YinusaFatai from './assets/cert Mar. 2025/YINUSA FATAI OLUSOLA.jpg'
import SummerCodeCamp from "./Components/summercodecamp/SummerCodeCamp";


const cert = [
  {
    image: adekoya2,
    code: "NUP-CCP-2308009",
    name: 'Adekoya'
  },
  {
    image: akhaine,
    code: "NUP-CCP-2307014",
    name: 'Akhaine'
  },
  {
    image: karunwi,
    code: "NUP-CCP-2209014",
    name: 'Kurunwi'
  },
  {
    image: tolawanimi,
    code: "NUP-CCP-2305013",
  },
  {
    image: ugwu2,
    code: "NUP-CCP-2308010",
  },
  {
    image: olaitan,
    code: "NUP-CCP-2303004",
  },
  {
    image: olajide,
    code: "NUP-CCP-2307006",
  },
  {
    image: olowu,
    code: "NUP-CCP-2307004",
  },
  {
    image: olusola,
    code: "NUP-CCP-2307012",
  },
  {
    image: omoyeni,
    code: "NUP-CCP-2306009",
  },
  {
    image: oyanienlen,
    code: "NUP-CCP-2306003",
  },
  {
    image: simbiat,
    code: "NUP-CCP-2308011",
  },
  {
    image: sylvesteer,
    code: "NUP-CCP-2305009",
  },
  {
    image: ogunleye,
    code: "NUP-CCP-2307001",
  },
  {
    image: ogunjobi,
    code: "NUP-CCP-2308014",
  },
  {
    image: okoh,
    code: "NUP-CCP-2306013",
  },
  {
    image: olaewe,
    code: "NUP-CCP-2306004",
  },
  {
    image: michael,
    code: "NUP-CCP-2307013",
  },
  {
    image: nwadiogbu,
    code: "NUP-CCP-2306012",
  },
  {
    image: obe,
    code: "NUP-CCP-2305003",
  },
  {
    image: oduyeemi,
    code: "NUP-CCP-2304004",
  },
  {
    image: feeyijimi,
    code: "NUP-CCP-2308013",
  },
  {
    image: fisayo,
    code: "NUP-CCP-2306002",
  },
  {
    image: ifekoya,
    code: "NUP-CCP-2308002",
  },
  {
    image: iwenjiwe,
    code: "NUP-CCP-2305012",
  },
  {
    image: fajana,
    code: "NUP-CCP-2302010",
  },
  {
    image: bolatito,
    code: "NUP-CCP-2306001",
  },
  {
    image: emeka_akam,
    code: "NUP-CCP-2307003",
  },
  {
    image: chukwu,
    code: "NUP-CCP-23010001",
  },
  {
    image: agwaniru,
    code: "NUP-CCP-2305004",
  },
  {
    image: ajala,
    code: "NUP-CCP-2305002",
  },
  {
    image: ajayi,
    code: "NUP-CCP-2307007",
  },
  {
    image: baasit,
    code: "NUP-CCP-2305005",
  },

  {
    image: nworie,
    code: "NUP-CCP-2209002",
  },
  {
    image: adebiyi,
    code: "NUP-CCP-2209003",
  },
  {
    image: obasi,
    code: "NUP-CCP-2301012",
  },
  {
    image: mustapha,
    code: "NUP-CCP-2209001",
  },
  {
    image: ifechukwu,
    code: "NUP-CCP-2312001",
  },
  {
    image: adeniji,
    code: "NUP-CCP-2302014",
  },
  {
    image: obilonu,
    code: "NUP-CCP-2209013",
  },
  {
    image: opeyemi,
    code: "NUP-CCP-2304018",
  },
  {
    image: ogunuyo,
    code: "NUP-CCP-2301009",
  },
  {
    image: olaleye,
    code: "NUP-CCP-2303009",
  },
  {
    image: chuma,
    code: "NUP-CCP-2301001",
  },
  {
    image: obi,
    code: "NUP-CCP-2301006",
  },
  {
    image: adaeze,
    code: "NUP-CCP-2304013",
  },
  {
    image: simi,
    code: "NUP-CCP-2209005",
  },
  {
    image: dennis,
    code: "NUP-CCP-2304024",
  },
  {
    image: blessing,
    code: "NUP-CCP-2209008",
  },
  {
    image: ojo,
    code: "NUP-CCP-2303005",
  },
  {
    image: oyesina,
    code: "NUP-CCP-2304019",
  },
  {
    image: emmanuel,
    code: "NUP-CCP-2301005",
  },
  {
    image: yasir,
    code: "NUP-CCP-2304017",
  },
  {
    image: ademisoye,
    code: "NUP-CCP-2304006",
  },
  {
    image: augustine,
    code: "NUP-CCP-2209007",
  },
  {
    image: jeremiah,
    code: "NUP-CCP-2301002",
  },
  {
    image: adebayo,
    code: "NUP-CCP-2301018",
  },
  {
    image: abayomi,
    code: "NUP-CCP-2304027",
  },
  {
    image: arit,
    code: "NUP-CCP-2304016",
  },
  {
    image: chiagozie,
    code: "NUP-CCP-2301003",
  },
  {
    image: adaeze,
    code: "NUP-CCP-2303002",
  },
  {
    image: ugwu,
    code: "NUP-CCP-2209015",
  },
  {
    image: david,
    code: "NUP-CCP-2211005",
  },
  {
    image: perpetual,
    code: "NUP-CCP-2301007",
  },
  {
    image: james,
    code: "NUP-CCP-2301013",
  },
  {
    image: disu,
    code: "NUP-CCP-2304007",
  },
  {
    image: shittu,
    code: "NUP-CCP-2209011",
  },
  {
    image: adekoya,
    code: "NUP-CCP-2208014",
  },
  {
    image: ukaegbu,
    code: "NUP-CCP-2304025",
  },
  {
    image: olofi,
    code: "NUP-CCP-2301016",
  },

  // July 2024

  {
    image: Abednego,
    code: "NUP-CCP-24002004",
    name: "Abednego",
  },
  {
    image: Abosede,
    code: "NUP-CCP-24001004",
    name: "Abosede",
  },
  {
    image: Adegbite,
    code: "NUP-CCP-24002012",
    name: 'Adegbite'
  },
  {
    image: Adekoya,
    code: "NUP-CCP-24003025",
    name: 'Adekoya'
  },
  {
    image: Adeniji,
    code: "NUP-CCP-2311014",
    name: 'Adeniji'
  },
  {
    image: Adesanya,
    code: "NUP-CCP-24002015",
    name: 'Adesanya'
  },
  {
    image: AdeyemiNic,
    code: "NUP-CCP-24003002",
    name: 'Adeyemi'
  },
  {
    image: AdeyemiDoyin,
    code: "NUP-CCP-24001007",
    name: 'Adeyemi Doyin'
  },
  {
    image: Musodiq,
    code: "NUP-CCP-24001017",
    name: 'Raji Musodiq'
  },
  {
    image: Aham,
    code: "NUP-CCP-2312005",
    name: 'Aham'
  },
  {
    image: Aina,
    code: "NUP-CCP-2310006",
    name: 'Aina'
  },
  {
    image: Akinlade,
    code: "NUP-CCP-2312006",
    name:'Akinlade'
  },
  {
    image: Babatunde,
    code: "NUP-CCP-24003016",
    name:'Babatunde'
  },
  {
    image: Campbell,
    code: "NUP-CCP-2311015",
    name:'Campbell'
  },
  {
    image: Anucha,
    code: "NUP-CCP-2312010",
    name:'Anucha'
  },
  {
    image: Chinenye,
    code: "NUP-CCP-24002001",
    name: 'Chinenye'
  },
  {
    image: ChineyeAda,
    code: "NUP-CCP-24003005",
    name: 'Chineye Ada'
  },
  {
    image: Chioma,
    code: "NUP-CCP-2307002",
    name: 'Chioma'

  },
  {
    image: Chikwuikem,
    code: "NUP-CCP-24001009",
    name: 'Chikwuikem'
  },
  {
    image: DavidOlu,
    code: "NUP-CCP-24003004",
    name: 'David'
  },
  {
    image: Dedogbe,
    code: "NUP-CCP-24001015",
    name:'Dedogbe'
  },
  {
    image: Diana,
    code: "NUP-CCP-24001012",
    name:'Diana'
  },
  {
    image: Enyagam,
    code: "NUP-CCP-24002003",
    name:'Enyagam'
  },
  {
    image: Farinde,
    code: "NUP-CCP-24002009",
    name: 'Farinde'
  },
  {
    image: Godsent,
    code: "NUP-CCP-24003001",
    name:'Godsent'
  },
  {
    image: Obogu,
    code: "NUP-CCP-24001001",
    name:'Obogu James'
  },
  {
    image: Jolayemi,
    code: "NUP-CCP-2310005",
    name:'Jolayemi'
  },
  {
    image: Joseph,
    code: "NUP-CCP-24003013",
    name:'Joseph'
  },
  {
    image: Kalu,
    code: "NUP-CCP-24003007",
    name:'Kalu'
  },
  {
    image: Kehinde,
    code: "NUP-CCP-2311007",
    name:"Kehinde"
  },
  {
    image: Khalil,
    code: "NUP-CCP-24002010",
    name:'Khalil'
  },
  {
    image: Ejiowo,
    code: "NUP-CCP-24002013",
    name:'Ejiowo'
  },
  {
    image: Mariam,
    code: "NUP-CCP-24002011",
    name:'Mariam'
  },
  {
    image: Maureen,
    code: "NUP-CCP-24002020",
  },
  {
    image: MichaelDavid,
    code: "NUP-CCP-24001005",
  },
  {
    image: Moyosore,
    code: "NUP-CCP-24002014",
  },
  {
    image: Ogunfuwa,
    code: "NUP-CCP-24002006",
  },
  {
    image: Ogunjobi,
    code: "NUP-CCP-24001014",
  },
  {
    image: Ohikhena,
    code: "NUP-CCP-24003022",
  },
  {
    image: Okim,
    code: "NUP-CCP-24003020",
  },
  {
    image: Olabamiji,
    code: "NUP-CCP-24002025",
  },
  {
    image: Oladimeji,
    code: "NUP-CCP-24002017",
  },
  {
    image: Olanrewaju,
    code: "NUP-CCP-2310007",
  },
  {
    image: Olawuyi,
    code: "NUP-CCP-24002019",
    name: "Olawuyi",
  },
  {
    image: Olayode,
    code: "NUP-CCP-24001006",
    name: "Olayode",
  },
  {
    image: Oluwaseyi,
    code: "NUP-CCP-24001020",
  },
  {
    image: Onyeike,
    code: "NUP-CCP-24003021",
  },
  {
    image: Osobajo,
    code: "NUP-CCP-24001016",
  },
  {
    image: Oyedele,
    code: "NUP-CCP-24003008",
  },
  {
    image: Oyekan,
    code: "NUP-CCP-2311001",
  },
  {
    image: Princely,
    code: "NUP-CCP-24001019",
  },
  {
    image: Rex,
    code: "NUP-CCP-2309011",
  },
  {
    image: Afolabi,
    code: "NUP-CCP-24002018",
  },
  {
    image: Silas,
    code: "NUP-CCP-24001018",
    name:'Silas Abayomi Oluwafemi'
  },
  {
    image: Simon,
    code: "NUP-CCP-24002002",
  },
  {
    image: Temitope,
    code: "NUP-CCP-2312004",
  },
  {
    image: TijaniOla,
    code: "NUP-CCP-24002007",
  },
  {
    image: Tunde,
    code: "NUP-CCP-231004",
  },
  {
    image: Uje,
    code: "NUP-CCP-24002026",
  },
  {
    image: Adeniyi,
    code: "NUP-CCP-2311014",
  },
  {
    image: Musawir,
    code: "NUP-CCPL-24003001",
  },
  {
    image: Oluwafe,
    code: "NUP-CCPL-2309013",
  },
  {
    image: Oreka,
    code: "NUP-CCPL-2309006",
  },
  {
    image: Oyekan,
    code: "NUP-CCPL-2311001",
  },
  {
    image: AbdullahiNasiru,
    code: "NUP-CCPA-25003005",
  },
  {
    image: AhmadUsman,
    code: "NUP-CCPA-25003004",
  },
  {
    image: Ebipadou,
    code: "NUP-CCPA-25003006",
  },
  {
    image: Kalejaiye,
    code: "NUP-CCPA-25003007",
  },
  {
    image: Imonite,
    code: "NUP-CCPA-25003001",
  },
  {
    image: Miriam,
    code: "NUP-CCPA-25003002",
  },
  {
    image: Obaje,
    code: "NUP-CCPA-25003003",
  },
  {
    image: YinusaFatai,
    code: "NUP-CCPA-25003008",
  },
];

// Function to find the certificate based on the code from the URL
export const findCertificateByCode = (code) => {
  return cert.find((c) => c.code === code);
};

export function Routes() {
  const routeElements = useRoutes([
    {
      element: <About />,
      path: "/about-us",
    },
    {
      element: <OurServices />,
      path: "/services",
    },
    {
      element: <CodeCamp />,
      path: "/code-camp",
    },
    {
      element: <UkCohorts />,
      path: "/uk-cohorts",
    },
    {
      element: <SummerCodeCamp/>,
      path: "/summer-code-camp",
    },
    {
      element: <ItServices />,
      path: "/it-services",
    },
    {
      element: <Home />,
      path: "/",
    },
    {
      element: <ErrorPage />,
      path: "*",
    },
    {
      element: <Certificates certificates={cert} />,
      path: "/:code",
    },
  ]);

  return routeElements;
}
